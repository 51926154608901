/*-----------------Fonts-----------------*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic');

/*-----------------Specification-----------------*/

body {
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 300;
    color: #fff;
    margin: 0 auto;
    font-size: 22px;
    text-align: center;
    line-height: 1.25em;
    outline: none;
}

.container{
    width: 1140px;
    margin: 0 auto;
    position: relative;
}

.clearfix:after {
    content:'';
    display: table;
    clear: both;
    width: 100%;
}

.main-wrap {
	overflow-x: hidden;
}


h1, h2, h3, h4, h5 {
    font-weight: 300;
    margin: 0;
}

h1, h2 {
	font-size: 44px;
	text-transform: uppercase;
}

h2 {
	padding-top: 105px;
}

h3 {
	font-size: 40px;
}

h4 {
	font-size: 22px;
	font-weight: 600;
	color: #000;
}

h5 {
	font-size: 26px;
	font-weight: 400;
	color: #000;
}

ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

input {
    border-style: none;
}

p {
    margin: 0px;
}

a {
    text-decoration: none;
    outline: none;
}

button {
	outline: none;
	border: none;
	box-shadow: none;
	cursor: pointer;
	padding: 0;
	margin: 0;
}

.cta-btn {
	width: 290px;
	height: 56px;
	display: block;
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	background: #b4917e;
	color: #fff;
	line-height: 56px;
}

.cta-btn:hover {
	background: #8f7160;
	transition: 0.2s ease; 
}

.bold-text {
	font-weight: 700;
	color: #b4917e;
}

.line {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.point {
	position: relative;
	text-align: left;
}

.point:before {
	content: '';
	display: block;
	width: 14px;
	height: 14px;
	background: #b4917e;
	border-radius: 2px;
	position: absolute;
	top: 10px;
	left: -23px;
	/* Rotate */
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
/* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
}

/*-----------------HEADER SECTION-----------------*/

#header {
	 background: url(../img/background.jpg) no-repeat center top/cover;
}

/*-----------------Hat-----------------*/

.logo-wrap {
	float: left;
	text-align: left;
	padding-top: 21px;
}

.underlogo-text {
	font-size: 13px;
	margin-top: -5px;
	text-transform: uppercase;
}

.logo-wrapper {
	float: left;
	margin-right: 50px;
}

.logo__text {
	float: left;
	font-size: 18px;
	margin-top: 2px;
}

.phone-wrap {
	float: right;
	text-align: right;
	padding-top: 14px;
}

.phone-wrap p {
	font-size: 23px;
}

.phone-wrap .cta-btn {
	background: none;
	border: 1px solid #b4917e;
	color: #b4917e;
	width: 196px;
	height: 32px;
	line-height: 32px;
	margin-right: 0;
	margin-left: auto;
	margin-top: 4px;
}

.main-screen p {
	font-size: 34px;
}

/*-----------------Main-----------------*/

h1 {
	margin: 80px 0 30px 0;
}

.main-screen p {
	margin-bottom: 45px;
}

.header__line {
	width: 455px;
	height: 1px;
	background: #b4917e;
	margin-bottom: 2px;
}

.header__merits {
	margin-top: 60px;
}

.header__merit {
	width: 32%;
	float: left;
}

.header__merit .point {
	width: 220px;
	display: block;
	margin-right: auto;
	margin-left: auto;
	line-height: 1.25em;
}

.header__merits .vertical-line {
	float: left;
	margin-right: 2px;
	margin-top: -7px;
}

/*-----------------HEADER FORM-----------------*/

.header__form p {
	margin: 47px 0 49px 0;
}

.header__form_input {
	width: 238px;
	height: 36px;
	border: 1px solid #fff;
	padding-left: 20px;
	font-size: 15px;
	background: rgba(255,255,255,0.25);
	border-radius: 3px;
	color: #fff;
	outline: none;
	float: left;
	margin: 10px 18px 50px 0;
}

/* placeholder css */

::-webkit-input-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}
::-moz-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}

/* Firefox 19+ */

:-moz-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}

/* Firefox 18- */

:-ms-input-placeholder {
   color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}

/* placeholder hover css */

:hover::-webkit-input-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}
:hover::-moz-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}/* Firefox 19+ */
:hover:-moz-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}/* Firefox 18- */
:hover:-ms-input-placeholder {
  color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}

/* placeholder not hover css */

:not(:hover)::-webkit-input-placeholder { transition: 0.1s; }
:not(:hover)::-moz-placeholder { transition: 0.1s; } /* Firefox 19+ */
:not(:hover):-moz-placeholder { transition: 0.1s; } /* Firefox 18- */
:not(:hover):-ms-input-placeholder { transition: 0.1s; }

/* placeholder focus css */

:focus::-webkit-input-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}
:focus::-moz-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}/* Firefox 19+ */
:focus:-moz-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}/* Firefox 18- */
:focus:-ms-input-placeholder {
    color:rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}

.header__form .cta-btn {
	float: right;
}

/*-----------------RANGE SECTION-----------------*/

#range {
	color: #000;
}

#range .undertitle__text {
	color: #555;
	margin: 17px 0 28px 0;
}

.range__items {
	margin-top: 38px;
	margin-bottom: 5px;
}

.range__item {
	width: 20%;
	float: left;
}

.range__item p {
	margin-top: 8px;
}

.range__main-wrap {
	display: table;
	width: 100%;
}

.range__img-wrap {
	display: table-cell;
	vertical-align: middle;
	height: 120px;
	text-align: center;
	width: 20%;
}

#range .cta-btn {
	width: 555px;
	height: 68px;
	line-height: 68px;
	text-transform: uppercase;
	margin: 35px auto 120px auto;
}

/*-----------------PROFIT SECTION-----------------*/

#profit {
	background: url(../img/profitbg.jpg) no-repeat center top/cover;
	color: #000;
}

#profit h2 {
	padding-top: 76px;
	margin-bottom: 17px;
}

#profit .bold-text {
	color: #000;
}

.profits {
	padding: 55px 0 22px 0;
}

.profit__item {
	width: 33%;
	float: left;
}

.profit__number {
	font-size: 85px;
	color: #b4917e;
	line-height: 1em;
}

.profit__description { 
	padding: 35px 45px;
}

.overprice {
	font-size: 35px;
}

.profit__main-wrap {
	display: table;
	width: 100%;
}

.profit__img-wrap {
	display: table-cell;
	vertical-align: middle;
	height: 140px;
	text-align: center;
	width: 33%;
}

/*-----------------WHY SECTION-----------------*/

#why {
	background: url(../img/whybg.jpg) no-repeat center top/cover;
}

#why h2 {
	padding: 70px 0 18px 0;
}

.why__lists {
	padding: 35px 0 72px 0;
}

.why__list {
	width: 490px;
}

.why__list_first {
	float: left;
}

.why__list_second {
	float: right;
	margin-right: 70px;
}

/*-----------------REORDER SECTION-----------------*/

.reorder {
	background: #b4917e;
	padding: 23px 0;
	margin: 78px -15px 0 -15px;
}

/*-----------------CHECK SECTION-----------------*/

#check {
	background: url(../img/check.jpg) no-repeat center top/cover;
	padding-bottom: 30px;
}

#check h3 {
	padding-top: 75px;
}

#check .header__form p {
	margin-bottom: 58px;
}

/*-----------------MORE SECTION-----------------*/

#more {
	color: #000;
	text-align: center;
}

#more h2 {
	padding-top: 127px;
}

.more__wrap {
	margin-top: 76px;
}

.more__item {
	float: left;
	width: 25%;
	margin-bottom: 35px;
}

.romb {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 262px;
	background: url(../img/romb.png) no-repeat center;
}

h4 {
	margin-top: 11px;
}

.more__romb_wrap {
	display: table;
	width: 100%;
}

.more__romb {
	height: 272px;
	width: 262px;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.more__item p {
	font-size: 16px;
	line-height: 1.25em;
	padding: 12px 10px;
}

#more .reorder {
	margin-top: 20px;
	color: #fff;
}

/*-----------------START SECTION-----------------*/

#start	{
	background: url(../img/startbg.jpg) no-repeat center top/cover;
	text-align: center;
	padding-bottom: 34px;
}

#start h3 {
	padding-top: 90px;
}

#start .header__form p {
	margin-top: 33px;
	margin-bottom: 66px;
}

/*-----------------HOW SECTION-----------------*/

#how {
	color: #000;
	padding-bottom: 120px;
}

#how h2 {
	padding-top: 120px;
}

#how .undertitle__text {
	color: #555;
	margin-top: 17px;
}

.how__ways {
	text-align: left;
	font-size: 18px;
	line-height: 1.25em;
	margin-top: 53px;
}

.how__wrap {
	width: 555px;
	position: relative;
}

.how__milange {
	position: absolute;
	top: 270px;
    right: 0;
    z-index: -1;
}

.how__milange_second {
	top: 50px;
	left: 0;
}

.how__wrap img {
	margin: 5px 0;
}

.how__text_second {
	text-align: right;
}

.how__wrap_first {
	float: left;
}

.how__wrap_second {
	float: right;
}

.how__wrapper {
	margin-top: 40px;
}

.how__lines {
	float: left;
	margin-top: 20px;
}

#how .line {
	width: 278px;
}

#how .cta-btn {
	width: 260px;
	height: 46px;
	line-height: 46px;
	text-transform: uppercase;
	float: right;
}

.hidden-text {
	display: none;
}

/*-----------------ABOUT SECTION-----------------*/

#about {
	background: url(../img/profitbg.jpg) no-repeat center top/cover;
	color: #000;
	padding-bottom: 60px;
}

.about__wrapper {
	margin-top: 52px;
}

.about__wrap {
	width: 33%;
	float: left;
}

.about__wrap_title {
	font-size: 50px;
	font-weight: 400;
}

.about__wrap_title span {
	color: #b4917e;
}

.about__wrap_description {
	line-height: 1em;
	margin-top: 15px;
}

/*-----------------ABOUT SECTION-----------------*/

#moment {
	background: url(../img/momentbg.jpg) no-repeat center top/cover;
	padding-bottom: 74px;	
}

#moment h2 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.moment__main-wrap {
	float: left;
	margin-right: 15px;
}

.vertical-line {
	display: block;
	height: 58px;
	width: 1px;
	background: #b4917e;
	margin-right: 2px;
	float: left;
}

.moment__item p {
	float: left;
	width: 260px;
	margin-left: 13px;
	line-height: 1.2em;
}

.moment__item {
	width: 33%;
	text-align: left;
	font-size: 18px;
	float: left;
}

/*-----------------PROVIDE SECTION-----------------*/

#provide .reorder {
	margin-top: 0;
}

/*-----------------TRADEMARK SECTION-----------------*/

#trademark {
	color: #000;
	padding-bottom: 0;
	height: 1000px;
}

#trademark h2 {
	padding-top: 120px;
}

.trademark__first, .trademark__second, .trademark__third {
	width: 1140px;
}

.trademark__first, .trademark__third {
	margin-left: 80px;
}

.trademark__second {
	margin-left: -60px;
	margin-top: 0;
	position: relative;
	top: -115px;
}

.trademark__third {
	margin-top: 0;
	position: relative;
	top: -235px;
}

#trademark__second .more__romb_wrap {
	position: relative;
	z-index: 2;
}

/*-----------------BUISNESS SECTION-----------------*/

#buisness {
	background: url(../img/buisnessbg.jpg) no-repeat center top/cover;
	padding-bottom: 23px;
}

#buisness h2 {
	padding-top: 67px;
}

#buisness .undertitle__text {
	padding: 17px 120px;
}

.buisness__list {
	width: 610px;
	display: block;
	margin: 28px auto 50px auto;
}

.buisness__list li {
	margin-bottom: 4px;
}

#buisness .header__form p {
	margin-top: 23px;
	margin-bottom: 30px;
}

/*-----------------WORK SECTION-----------------*/

#work {
	background: url(../img/profitbg.jpg) no-repeat center top/cover;
	color: #000;
}

#work h2 {
	padding-top: 65px;
	padding-bottom: 50px;
}

.work__plan {
	margin-left: 50px;
	text-align: left;
}

.work__wrap {
	width: 33%;
	float: left;
	margin-bottom: 85px;
}

.work__wrap_fifth, .work__wrap_fourth {
	width: auto;
}

.work__wrap_fourth {
	margin-left: 170px;
}

.work__wrap_fifth {
	margin-left: 60px;
}

.work__wrap img {
	float: left;
	margin-right: 15px;
}

.work__wrap_text {
	float: left;
	width: 260px;
	font-size: 18px;
	line-height: 1.20em;
}

.work__wrap_text p {
	margin-top: 8px;
}

/*-----------------DELIVER SECTION-----------------*/

#deliver {
	color: #000;
}

#deliver h2 {
	padding-top: 125px;
}

.deliver__wrap {
	margin-top: 70px;
}

#deliver .reorder {
	margin-top: 42px;
	color: #fff;
	margin-bottom: 120px;
}

#deliver .romb {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 162px;
	background: url(../img/smallromb.png) no-repeat center;
	float: left;
}

#deliver .more__romb {
	height: 172px;
    width: 162px;
}

#deliver .more__item {
	width: 41%;
	margin-left: 100px;
}

#deliver .more__item p {
	float: left;
	width: 180px;
	text-align: left;
	margin-top: 15px;
}

#deliver .more__item p:last-child {
	margin-top: 10px;
	margin-left: 30px;
}

/*-----------------WHERE SECTION-----------------*/

#where {
	background: url(../img/wherebg.jpg) no-repeat center top/cover;
	padding-bottom: 38px;
}

#where h3 {
	line-height: 1.25em;
	padding-top: 82px;
}

#where .header__form p {
	margin-top: 22px;
}

/*-----------------CONTACTS SECTION-----------------*/

#contacts {
	color: #000;
	padding-bottom: 80px;
}

#contacts h2 {
	text-transform: none;
	padding-top: 84px;
}

.contacts__wrap {
	margin-top: 68px;
}

#map {
	width: 654px;
	height: 362px;
	float: left;
	margin-right: 30px;
}

.contacts__wrap_text {
	float: left;
	text-align: left;
}

.contacts__wrap_title {
	margin-top: -23px;
	margin-bottom: 30px;
}
.contacts__wrap_item img {
	float: left;
	margin-right: 10px;
}

.contacts__wrap_description {
	float: left;
	font-size: 20px;
	font-weight: 400;
	line-height: 1em;
	margin-bottom: 33px;
}

/*-----------------FOOTER SECTION-----------------*/

#footer {
	background: url(../img/footerbg.jpg) no-repeat center top/cover;
}

.footer {
	padding: 30px 0 25px 0;
}

.social__wrap {
	text-align: center;
	float: left;
	margin-left: 225px;
	font-size: 18px;
	margin-top: -5px;
}

.social__wrap i {
	color: #b4917e;
	font-size: 40px;
	margin-right: 10px;
	margin-top: 7px;
}

.rigth__wrap {
	text-align: left;
	float: right;
	font-size: 20px;
	line-height: 1em;
}

.rigth__wrap a {
	font-size: 20px;
	color: #b4917e;
	text-decoration: underline;
}

.rigth__wrap a:hover {
	color: #8f7160;
}

#loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(240, 240, 240, 0.91);
    overflow: hidden;
    display: none;
    z-index: 11;
}

#loader img {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
    z-index: 11;
}

/*-----------------MODAL SECTION-----------------*/

.animated-modal {
	width: 450px;
	text-align: center;
	color: #000;
	position: relative;
	z-index: -1;
}

.animated-modal .header__form p {
	margin: 15px 0;
	font-size: 18px;
}

.animated-modal .header__form .cta-btn {
	margin-right: auto;
	margin-left: auto;
	float: none;
	margin-top: 15px;
	margin-bottom: 15px;
}

.animated-modal .header__form_input {
	float: none;
	background: #fff;
	border: 2px solid #b4917e;
	color: #000;
	width: 270px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
}

.confidencial-window {
	width: 96%;
}

.confidencial-window h4 {
	margin-bottom: 10px;
}

/* placeholder css */

.animated-modal .header__form_input::-webkit-input-placeholder {
    color:rgb(0,0,0);
    font-weight: 300;
    font-size: 15px;
}
.animated-modal .header__form_input::-moz-placeholder {
    color:rgb(0,0,0);
    font-weight: 300;
    font-size: 15px;
}

/* Firefox 19+ */

.animated-modal .header__form_input:-moz-placeholder {
    color:rgb(0,0,0);
    font-weight: 300;
    font-size: 15px;
}

/* Firefox 18- */

.animated-modal .header__form_input:-ms-input-placeholder {
  	color:rgb(0,0,0);
    font-weight: 300;
    font-size: 15px;
}

.trademark-window {
	width: 800px;
	text-align: left;
}

.trademark-window img {
	width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
}
   